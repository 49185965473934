import React, { Component } from 'react';
import './App.css';
import { RedocStandalone } from 'redoc';
import defaultTheme from './redoc.ts'

class App extends Component {
  render() {
    return (
      <RedocStandalone
        specUrl={process.env.REACT_APP_SPEC_URL}
        options={{
          nativeScrollbars: true,
          theme: defaultTheme,
          hideHostname: true,
          requiredPropsFirst: true,
          hideDownloadButton: true,
        }}
      />
    );
  }
}

export default App;
